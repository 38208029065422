/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .mm-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
  
    .pp-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

/* For all Forms <start> */

#errors-section {
  p {
      color: var(--ion-color-danger-shade);
  }
}

/* For all Forms <end> */


.profile-popover-class {
  --background: white;
}


.workflow-modal-class {
  --min-width:60%;
  --min-height: 80%;
}

.lead-modal-class {
  --min-width:50%;
  --min-height: 60%;
}

.delete-confirm-class {
  --width:500px;
  --max-width:80%;

  /* Used in Confirm Alert Box */
  div.spaced-out-text {
    line-height: 1.6em;
    color: var(--ion-color-dark-tint);
  }

  .alert-delete-btn {
    background-color: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
  }
}

.loader-class {
  --background: var(--ion-color-light);
  --spinner-color: var(--ion-color-light-contrast);
  color: var(--ion-color-light-contrast);
}

/* Alert Interface: set color for alert options (multiple selection) */
.select-class .select-interface-option .alert-radio-label {
  color: var(--ion-color-light-contrast);
}

/* Alert Interface: set color for checked alert options (multiple selection) */
.select-class .select-interface-option[aria-checked=true] .alert-radio-label {
  color: var(--ion-color-light-contrast);
}

/* Alert Interface: set color for alert options (multiple selection) */
.select-class .select-interface-option .alert-checkbox-label {
  color: var(--ion-color-light-contrast);
}

/* Alert Interface: set color for checked alert options (multiple selection) */
.select-class .select-interface-option[aria-checked=true] .alert-checkbox-label {
  color: var(--ion-color-light-contrast);
}

ion-content.page-content {
  --padding-top: 1em;
  --padding-start: 1em;
  --padding-end: 1em;
  --padding-bottom: 1em;
}


/* CSS below to fix issues with frameworks */

/* This handles CSS for autofilled text inputs */

input:-webkit-autofill, input:-webkit-autofill:focus {
  height: 0px;
  box-shadow:0 0 0 10px var(--ion-color-light) inset;
  -webkit-box-shadow:0 0 0 10px var(--ion-color-light) inset;
  -webkit-text-fill-color: black;
  font-weight: 500px;
}

/*
 * This adds a scroll bar to ionic alerts 
 * https://github.com/ionic-team/ionic-framework/issues/18487
 */
 .alert-radio-group::-webkit-scrollbar, .alert-checkbox-group::-webkit-scrollbar {
  width: 7px;
  display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track, .alert-checkbox-group::-webkit-scrollbar-track {
  background: var(--ion-color-light-shade);
}

.alert-radio-group::-webkit-scrollbar-thumb, .alert-checkbox-group::-webkit-scrollbar-thumb {
  background: var(--ion-color-primary-tint);
}

.five-column-grid {
  --ion-grid-columns: 10;
}

