// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


@font-face {   
  font-family: 'Roboto-Light';    //This is what we are going to call
  src: url('../assets/fonts/Roboto/Roboto-Light.ttf');
}

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Roboto-Light';
  --asmart-color: #00aaef;

  --ion-color-primary: #134971;
  --ion-color-primary-rgb: 19,73,113;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #114063;
  --ion-color-primary-tint: #2b5b7f;

  --ion-color-secondary: #454545;
  --ion-color-secondary-rgb: 69, 69, 69;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3d3d3d;
  --ion-color-secondary-tint: #585858;

  --ion-color-tertiary: #c2efff;
  --ion-color-tertiary-rgb: 194, 239, 255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #abd2e0;
  --ion-color-tertiary-tint: #c8f1ff;

  --ion-color-success: #5c7e5c;
  --ion-color-success-rgb: 92,126,92;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #516f51;
  --ion-color-success-tint: #6c8b6c;

  --ion-color-warning: #eed277;
  --ion-color-warning-rgb: 238, 210, 119;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d1b969;
  --ion-color-warning-tint: #f0d785;

  --ion-color-warninglight: #fff7db;
  --ion-color-warninglight-rgb: 255,247,219;
  --ion-color-warninglight-contrast: #000000;
  --ion-color-warninglight-contrast-rgb: 0,0,0;
  --ion-color-warninglight-shade: #e0d9c1;
  --ion-color-warninglight-tint: #fff8df;

  --ion-color-danger: #ca495a;
  --ion-color-danger-rgb: 202,73,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #b2404f;
  --ion-color-danger-tint: #cf5b6b;

  --ion-color-dark: #383838;
  --ion-color-dark-rgb: 56, 56, 56;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #313131;
  --ion-color-dark-tint: #4c4c4c;

  --ion-color-medium: #a6a6a6;
  --ion-color-medium-rgb: 166, 166, 166;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #929292;
  --ion-color-medium-tint: #afafaf;

  --ion-color-light: #fafafa;
  --ion-color-light-rgb: 250, 250, 250;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dcdcdc;
  --ion-color-light-tint: #fbfbfb;

  --ion-color-plainwhite: #ffffff;
  --ion-color-plainwhite-rgb: 255,255,255;
  --ion-color-plainwhite-contrast: #000000;
  --ion-color-plainwhite-contrast-rgb: 0,0,0;
  --ion-color-plainwhite-shade: #e0e0e0;
  --ion-color-plainwhite-tint: #ffffff;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #134971;
    --ion-color-primary-rgb: 19,73,113;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #114063;
    --ion-color-primary-tint: #2b5b7f;

    --ion-color-secondary: #454545;
    --ion-color-secondary-rgb: 69, 69, 69;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #3d3d3d;
    --ion-color-secondary-tint: #585858;

    --ion-color-tertiary: #c2efff;
    --ion-color-tertiary-rgb: 194, 239, 255;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #abd2e0;
    --ion-color-tertiary-tint: #c8f1ff;

    --ion-color-success: #5c7e5c;
    --ion-color-success-rgb: 92,126,92;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #516f51;
    --ion-color-success-tint: #6c8b6c;

    --ion-color-warning: #eed277;
    --ion-color-warning-rgb: 238, 210, 119;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #d1b969;
    --ion-color-warning-tint: #f0d785;

    --ion-color-warninglight: #fff7db;
    --ion-color-warninglight-rgb: 255,247,219;
    --ion-color-warninglight-contrast: #000000;
    --ion-color-warninglight-contrast-rgb: 0,0,0;
    --ion-color-warninglight-shade: #e0d9c1;
    --ion-color-warninglight-tint: #fff8df;

    --ion-color-danger: #ca495a;
    --ion-color-danger-rgb: 202,73,90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #b2404f;
    --ion-color-danger-tint: #cf5b6b;

    --ion-color-dark: #383838;
    --ion-color-dark-rgb: 56, 56, 56;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #313131;
    --ion-color-dark-tint: #4c4c4c;

    --ion-color-medium: #a6a6a6;
    --ion-color-medium-rgb: 166, 166, 166;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #929292;
    --ion-color-medium-tint: #afafaf;

    --ion-color-light: #fafafa;
    --ion-color-light-rgb: 250, 250, 250;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #dcdcdc;
    --ion-color-light-tint: #fbfbfb;

    --ion-color-plainwhite: #ffffff;
    --ion-color-plainwhite-rgb: 255,255,255;
    --ion-color-plainwhite-contrast: #000000;
    --ion-color-plainwhite-contrast-rgb: 0,0,0;
    --ion-color-plainwhite-shade: #e0e0e0;
    --ion-color-plainwhite-tint: #ffffff;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #ffffff; /* #121212; */
    --ion-background-color-rgb: 255, 255, 255; /* 18,18,18; */

    --ion-text-color: #121212;
    --ion-text-color-rgb: 18, 18, 18;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #e1e1e1;

    --ion-toolbar-background: #f1f1f1;

    --ion-tab-bar-background: #f1f1f1;

    --ion-card-background: #e1e1e1;
  }
}
